import { useMemo, useState } from "react";
import styles from "./Search.module.css";
import { getClassName, getStyle } from "../base";

export const Search = (props) => {
  const [text, setText] = useState("");
  const style = useMemo(() => getStyle(props.style), [props.style]);
  const onClick = async () => await props.onClick(text);
  return (
    <div className={getClassName(styles.input, props.className)} style={style}>
      <input
        type="search"
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Введите запрос для поиска"
        onKeyDown={async (e) => {
          if (e.key === "Enter") {
            await props.onClick(text);
          }
        }}
      />
      <button onClick={onClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="40"
          viewBox="0 -960 960 960"
          width="40"
        >
          <path
            fill="#6AB8C6"
            d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"
          />
        </svg>
      </button>
    </div>
  );
};
